import { KionButton } from "../../button/button"
import s from './styles.module.scss'

export function KionSuccess() {
  function moveTo(link: string) {
    window.location.assign(link)
  }

  return (
    <div style={{
      background: '#090B3F',
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <div className={s.wrapper}>
        <h2 className={s.title}>
            <div className={s.icon}>
              <img src="/images/done-icon.svg"/>
            </div>
            Подписка «KION + МТС Premium» успешно активирована!
        </h2>
        <div className={s.subtitle}>
            <div className={s.label}>
              Теперь вы можете перейти на сайт кинотеатра или <br/>
              установить наше приложение
            </div>
        </div>

        <KionButton onClick={() => moveTo('https://kion.ru')}>
            Перейти на kion.ru
        </KionButton>

        <div className={s['app-ref-row']}>
            <img src="/images/appstores//google-play.svg"
                onClick={() => moveTo('https://kion.onelink.me/26fZ/efbea3a3')}/>
            <img src="/images/appstores//app-store.svg"
                onClick={() => moveTo('https://kion.onelink.me/26fZ/efbea3a3')}/>
            <img src="/images/appstores//app-gallery.svg"
                onClick={() => moveTo('https://appgallery.huawei.com/#/app/C101183499')}/>
        </div>
      </div>
    </div>
  )
}
