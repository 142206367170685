import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreCtx } from "../../store";
import { forceRedirect, setForceRedirect } from "../../store/appStore";
import { KION_TARIFF_PERIOD } from "../../util/interfaces";
import { KION_PICTURE_TYPE, Subscription } from "../../util/promo";
import { ChooseSubscription } from "./choose-subscription/choose-subscriptioon";
import { PaymentConfirm } from "./payment-confirm/payment-confirm";
import { PaymentInit } from "./payment-init/payment-init";

export enum PaymentStep {
	Choose, // выбор подписки
    Init,
    Phone,
    Card,
    Error,
    Success
}

export const SubscriptionOffer = observer(() => {
    const httpService = useStoreCtx().appStore;

    const selectedSubscription: Subscription = httpService.selectedSubscription

	useEffect(() => {
        httpService.selectedSubscription = httpService.subscriptions.find(
            (sub) => sub.promotion.timeToLiveValue?.toString() === window.sessionStorage.getItem("subscription_period")
        ) ?? httpService.subscriptions[0]
    }, [])

    const [step, setStep] = useState<PaymentStep>(
        window.sessionStorage.getItem("subscription_period") ? PaymentStep.Init : PaymentStep.Choose
    );

    function isSelectedPaymentMethod() {
        return [PaymentStep.Phone, PaymentStep.Card].indexOf(step) !== -1
    }

    // const navigate = useNavigate();

    // Чтобы при нажатии браузерной кнопки "назад" - перекидывало на главную
    // useEffect(() => {
    //     window.history.pushState(null, document.title)

    //     const handlePopEvent = (event: PopStateEvent) => navigate('/')
    //     window.addEventListener('popstate', handlePopEvent)

    //     return () => {
    //         window.removeEventListener('popstate', handlePopEvent);
    //     }
    // }, []);
  
    return (
        <div style={{
            background: '#090B3F',
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>

			{step === PaymentStep.Choose && (
				<ChooseSubscription 
					onNext={() => setStep(PaymentStep.Init)}
				/>
			)}

            {step === PaymentStep.Init && (
                <PaymentInit 
                    subscription={selectedSubscription}
                    onNext={(event) => setStep(event)} 
                />
            )}
         
            {isSelectedPaymentMethod() && (
                <PaymentConfirm
                    paymentMethod={step}
                    subscription={selectedSubscription}
                    onNext={(event) => setStep(event)} 
                />
            )}
       </div>
    )
})