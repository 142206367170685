import clsx from 'clsx'
import { useRef, useState } from 'react'
import s from './styles.module.scss'

export interface KionSelectOption {
  label: string
  icon?: string
  tooltip?: string
  step: number
}

export function KionSelect(props: {
    placeholder: string
    label: string
    options: KionSelectOption[]
    onSelect: (step: number) => void
}) {
    const refDropdown = useRef<any>(null)
    const [selected, setSelected] = useState<KionSelectOption | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    function openOptions() {
        setIsOpen(true)
        document.body.addEventListener('mouseup', outsideClickHandler)
    }

    function closeOptions() {
        setIsOpen(false)
        document.body.removeEventListener('mouseup', outsideClickHandler)
    }

    function outsideClickHandler(e: MouseEvent) {
        if (e.target !== refDropdown.current && !refDropdown.current?.contains(e.target as HTMLElement)) {
            closeOptions()
        }
    }

    function onSelect(opt: KionSelectOption) {
        setSelected(opt)
        props.onSelect(opt.step)
        closeOptions()
    }

    return (
        <div className={s.select__wrapper}>
            <div className={s.select__label}>
                { props.label }
            </div>
            <div 
                className={clsx(
                    isOpen && s.open, 
                    s.select__input
                )}
                onClick={openOptions}
            >
            {selected ? (
                <>
                    <img 
                        src={selected.icon}
                        className={s.icon}
                    />
                    <div>{ selected.label }</div>
                </>
                ) : (
                    <span>
                        { props.placeholder }
                    </span>
                )}
                <img 
                    className={s['dropdown-icon']}
                    src="/images/back.svg"
                />
            </div>

            {isOpen && (
                <div
                    ref={refDropdown}
                    className={s.select__options}
                >
                    {props.options.map((opt) => (
                        <div
                            key={opt.icon}
                            className={clsx(
                                s['select__options-item'], 
                                (selected ? selected.step === opt.step : false) && s.selected)
                            }
                            onClick={() => onSelect(opt)}
                        >
                            {opt.icon && (
                                <img 
                                    src={opt.icon}
                                    className={s.icon}
                                />
                            )}
                            <div className={s.label}>
                                { opt.label }<br/>
                                <span className={s.tooltip}>
                                    { opt.tooltip }
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}